import VueBaseController from '../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/api_keys/index.vue') }

  static values = {
    accountId: String,
    openAiKey: String,
    currentSerpApiKey: String,
    currentExtractorKey: String,
    currentKeywordsEverywhereApiKey: String,
    currentValueSerpApiKey: String,
  }

  connect() {
    super.connect({
      accountId: this.accountIdValue,
      openAiKey: this.openAiKeyValue,
      currentSerpApiKey: this.currentSerpApiKeyValue,
      currentExtractorKey: this.currentExtractorKeyValue,
      currentKeywordsEverywhereApiKey: this.currentKeywordsEverywhereApiKeyValue,
      currentValueSerpApiKey: this.currentValueSerpApiKeyValue
    })
  }
}
