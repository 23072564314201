import VueBaseController from '../vue_base'

export class IndexController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/businesses/index.vue') }

  static values = {
    mapKey: String
  }
}

export class ShowController extends VueBaseController {
  static get COMPONENT() { return () => import('@/vue/pages/clients/businesses/show.vue') }

  static values = {
    businessId: String,
    analyticsConnected: Boolean,
    lastGscSync: String,
    lastGaSync: String,
    gaInitialSync: String,
    gscInitialSync: String,
    gscEnabled: Boolean,
    gscConnected: Boolean,
    gaEnabled: Boolean,
    gaConnected: Boolean
  }

  connect() {
    super.connect({
      businessId: this.businessIdValue,
      analyticsConnected: this.analyticsConnectedValue,
      lastGscSync: this.lastGscSyncValue,
      lastGaSync: this.lastGaSyncValue,
      gaInitialSync: this.gaInitialSyncValue,
      gscInitialSync: this.gscInitialSyncValue,
      gscEnabled: this.gscEnabledValue,
      gscConnected: this.gscConnectedValue,
      gaEnabled: this.gaEnabledValue,
      gaConnected: this.gaConnectedValue
    })
  }

  disconnect() {
    consumer.subscriptions.remove(this.subscription)
  }
}
